<template>
    <!-- <q-ajax-bar
        ref="bar"
        position="top"
        color="accent"
        size="2px"
        skip-hijack
    /> -->

    <div
        class="tw-min-h-screen tw-flex tw-flex-col sm:tw-justify-center tw-items-center tw-pt-6 sm:tw-pt-0 tw-bg-gray-100"
    >
        <div>
            <Link href="/">
                <ApplicationLogo
                    class="tw-w-20 tw-h-20 tw-fill-current tw-text-gray-500"
                />
            </Link>
        </div>

        <div
            class="tw-w-full sm:tw-max-w-md tw-mt-6 tw-px-6 tw-py-4 tw-bg-white tw-shadow-md tw-overflow-hidden sm:tw-rounded-lg"
        >
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import ApplicationLogo from "@/components/ApplicationLogo.vue"
import { onMounted, ref } from "vue"
import { QAjaxBar, useQuasar } from "quasar"
import { timeClearSpinner } from "../common/helpers"

const bar = ref<QAjaxBar | null>(null)
const $q = useQuasar()
let timer: NodeJS.Timeout

onMounted(() => {
    router.on("start", () => {
        $q.loading.show({
            delay: 150,
            spinnerColor: "primary",
        })
        timer = setTimeout(() => {
            $q.loading.hide()
            clearTimeout(timer)
        }, timeClearSpinner)
    })

    router.on("finish", () => {
        $q.loading.hide()
        clearTimeout(timer)
    })
})
</script>
